const GAMSRcMap = {
    "-9": "Model execution was interrupted",
    "0": "Normal return",
    "1": "Solver is to be called, the system should never return this number",
    "2": "There was a compilation error",
    "3": "There was an execution error",
    "4": "System limits were reached",
    "5": "There was a file error",
    "6": "There was a parameter error",
    "7": "There was a licensing error",
    "8": "There was a GAMS system error",
    "9": "GAMS could not be started",
    "10": "Out of memory",
    "11": "Out of disk",
    "15": "Model execution was interrupted",
    "109": "Could not create process/scratch directory",
    "110": "Too many process/scratch directories",
    "112": "Could not delete the process/scratch directory",
    "113": "Could not write the script gamsnext",
    "114": "Could not write the parameter file",
    "115": "Could not read environment variable",
    "144": "Could not spawn the GAMS language compiler (gamscmex)",
    "400": "Could not spawn the GAMS language compiler (gamscmex)",
    "145": "Current directory (curdir) does not exist",
    "401": "Current directory (curdir) does not exist",
    "146": "Cannot set current directory (curdir)",
    "402": "Cannot set current directory (curdir)",
    "148": "Blank in system directory (UNIX only)",
    "404": "Blank in system directory (UNIX only)",
    "149": "Blank in current directory (UNIX only)",
    "405": "Blank in current directory (UNIX only)",
    "150": "Blank in scratch extension (scrext)",
    "406": "Blank in scratch extension (scrext)",
    "151": "Unexpected cmexRC",
    "407": "Unexpected cmexRC",
    "152": "Could not find the process directory (procdir)",
    "408": "Could not find the process directory (procdir)",
    "153": "CMEX library could not be found (experimental)",
    "409": "CMEX library could not be found (experimental)",
    "154": "Entry point in CMEX library could not be found (experimental)",
    "410": "Entry point in CMEX library could not be found (experimental)",
    "155": "Blank in process directory (UNIX only)",
    "411": "Blank in process directory (UNIX only)",
    "156": "Blank in scratch directory (UNIX only)",
    "412": "Blank in scratch directory (UNIX only)",
    "141": "Cannot add path / unknown UNIX environment / cannot set environment variable",
    "232": "Driver error: incorrect command line parameters for gams",
    "1000": "Driver error: incorrect command line parameters for gams",
    "208": "Cannot add path / unknown UNIX environment / cannot set environment variable",
    "2000": "Cannot add path / unknown UNIX environment / cannot set environment variable",
    "184": "Driver error: problems getting current directory",
    "3000": "Driver error: problems getting current directory",
    "160": "Driver error: internal error: GAMS compile and execute module not found",
    "4000": "Driver error: internal error: GAMS compile and execute module not found",
    "126": "Driver error: internal error: cannot load option handling library",
    "5000": "Driver error: internal error: cannot load option handling library"
}
const sessionTokenExpirationSeconds = 604800;
export { GAMSRcMap, sessionTokenExpirationSeconds }
